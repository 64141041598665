@import "../../styles/variables.scss";

.container {
    padding-top: 60px;  
    
    .filterBarContainer {
        background: $gradient-desktop;
        padding: 1rem 5%;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    .sectionContainer {
        .iconsPresentation {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-top: 2rem;
            width:100%;

            p {
                font-family: Roboto-Regular;
                font-weight: 400;
                font-size: 1rem;
                line-height: 1.5rem;
                text-align: center;
                color: $dark;
            }
        }

        .advantageContainer {
            width: 100%;
            margin-top: 1rem;
            padding: 1.5rem;
            box-shadow:0px 0px 20px rgba(0,0,0,0.15);
            border-radius: 2px;

            .upBracket {
                display: flex;
                flex-wrap: wrap;
                
                @media (min-width: 1000px) {
                    justify-content: space-between;
                }

                @media (max-width: 1040px) {
                    // justify-content: center;
                    display: block;
                }

                p {
                    font-family: Roboto-Regular;
                    font-weight: 400;
                    font-size: 1rem;
                    line-height: 1.5rem;
                    color: $dark;
                    width: 100%;
                    margin-top: 1rem;
                    text-align: center;

                    @media (min-width: 1000px) {
                        width: auto;
                        margin-top: 0;
                    }
                }

                .qmentaColor {
                    background: $primary-accent;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }

            .bracket {
                margin: 0.5rem 0;

                img {
                    width: 100%;
                }
            }

            .downBracket {
                width: 100%;
                text-align: center;

                span {
                    font-family: Roboto-Regular;
                    font-weight: 400;
                    font-size: 1rem;
                    line-height: 1.5rem;
                    color: $dark;
                }
            }
        }
        .stepsOverall {
            width: 100%;
            display: flex;
            justify-content: center;
            .stepsContainer {
                margin-top: 2.5rem;
                display: flex;
                flex-wrap: wrap;
                max-width: 500px;
    
                @media (min-width: 1230px) {
                    max-width: 100%;
                    justify-content: space-between;
                }
    
                .step {
                    width: 100%;
                    margin: 1rem 0;
    
                    @media (min-width: 1230px) {
                        max-width: 30%;
                        margin:0;
                    }
                    
                    .imageBlock {
                        min-height: 25rem;
                        .stepImage {
                            display: flex;
                            justify-content: center;
                            box-shadow:0px 0px 20px rgba(0,0,0,0.15);
                            border-radius: 2px;
                            width: 100%;

                            @media (max-width: 1230px) {
                                display: block;
                            }
                            
                            img {
                                max-width: 100%;
                                object-fit: contain;

                                @media (max-width: 1230px) {
                                    height: 100%;
                                    width: 100%;
                                    max-width: 100%;
                                    max-height: 308px;
                                    object-fit: contain;
                                }
                            }
                        }
                        
                        @media (max-width: 1230px) {
                            min-height: 2rem;
                        }

                    }
    
                    .stepText {
                        margin-top: 1rem;
                        text-align: justify;
    
                        p {
                            font-family: Roboto-Regular;
                            font-weight: 400;
                            font-size: 1rem;
                            line-height: 1.5rem;
                            color: $dark;

                            @media (max-width: 1230px) {
                                text-align: center;
                                font-size: 18px;
                            }
                        }
                    }
                }
    
                .arrow {
                    display: inline;
                    max-width: 10%;
                    margin-top: 10%;

                    img {
                        width: 40px;
                        
                    }
    
                    @media (max-width: 1230px) {
                        margin-top: 0;
                        width: 100%;
                        position: relative;
                        left: 45%;
                        transform: translateX(-50%);
                        transform: rotate(90deg);
                    }
                }
            }
        }


        .techonologyInformation {
            display: flex;
            flex-wrap: wrap;
            .imageContainer {
                width: 100%;
                box-shadow:0px 0px 20px rgba(0,0,0,0.15);
                border-radius: 2px;
                margin: 1rem 0;

                @media (min-width: $breakpoint-tablet) {
                    width: 48%;

                    &:nth-child(1) {
                        margin-right: 0.5rem;
                    }

                    &:nth-child(2) {
                        margin-left: 0.5rem;
                    }

                    &:nth-child(3) {
                        margin-right: 0.5rem;
                    }

                    &:nth-child(4) {
                        margin-left: 0.5rem;
                    }
                }

                img {
                    width:100%;
                    height: auto;
                }

                .shape {
                    position: relative;
                    background: linear-gradient(#00c2b8, #00736e, #0075b2, #00abff) !important;
                    // background: $secondary;
                    background-size: auto 50%;
                    overflow: hidden;

                    @media (max-width: $breakpoint-tablet) {
                        background: $gradient-desktop;
                    }

                    &::before {
                        content: '';
                        position: absolute;
                        top: 30%;
                        right: 0;
                        width: 100%;
                        height: 80%;
                        background: $white;
                        -webkit-transform-origin: 100% 0;
                        -ms-transform-origin: 100% 0;
                        transform-origin: 100% 0;
                        -webkit-transform: skewY(3deg);
                        -ms-transform: skewY(3deg);
                        transform: skewY(3deg);
                        z-index: 0;
                        transform-origin: bottom;

                        @media (min-width: $breakpoint-tablet) {
                            top: 0;
                            width: 70%;
                            height: 100%;
                            -webkit-transform: skewX(-10deg);
                            -ms-transform: skewX(-10deg);
                            transform: skewX(-10deg);
                        }
                    }

                    @media (min-width: $breakpoint-tablet) {
                        background-size: 50% auto;
                    }

                    .documentationContainer {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;

                        .sdkContainer {
                            padding: 1rem;
                            padding-top: 0;
                            width: 100%;
                            height: 120px;
                            display: flex;
                            flex-wrap: wrap;

                            @media (min-width: $breakpoint-tablet) {
                                padding: 2.4rem 1.5rem;
                                width: 30%;
                                height: 100%;
                            }

                            img {
                                max-width: 50%;
                                height: 80%;

                                @media (min-width: $breakpoint-tablet) {
                                    max-width: 100%;
                                    height: auto;
                                }
                            }
            
                            p {
                                height: 50%;
                                font-family: Roboto-Regular;
                                font-weight: 400;
                                font-size: 1em;
                                margin-top: 1rem;
                                text-align: center;
                                color: $white;

                                @media (min-width: $breakpoint-tablet) {
                                    font-size: .75rem;
                                    margin-top: 0;
                                    width: 100%;
                                }

                                @media (max-width: $breakpoint-tablet) {
                                    font-size: 1.3rem;
                                }

                                @media (max-width: 463px) {
                                    font-size: 1.1rem;
                                }

                                @media (max-width: 363px) {
                                    font-size: .9rem;
                                }
                            }
                        }

                        .sdkExplanation {
                            position: relative;
                            width: 100%;
                            height: 100%;
                            padding: .75rem;
                            z-index: 1;
                            margin: 0 auto;

                            @media (min-width: $breakpoint-tablet) {
                                width: 63%;
                                margin: 0;
                                padding-right: 8%;
                            }

                            @media (max-width: 868px) {
                                min-height: 284px;
                            }

                            h2 {
                                font-family: Nunito-Sans;
                                font-weight: 400;
                                font-size: 1.25rem;
                                color: $dark;
                                line-height: 1.875rem;
                                margin-bottom: 1rem;
                                text-align: center;

                                @media (max-width: 868px) {
                                    min-height: 60px;
                                }
                            }

                            h3 {
                                font-family: Roboto-Regular;
                                font-weight: 400;
                                font-size: 1rem;
                                line-height: 1.5rem;
                                color: $dark;
                                text-align: center;

                                @media (max-width: 1095px) {
                                    min-height: 120px;
                                }

                            }

                            .button {
                                position: relative;
                                display: flex;
                                margin-top: 1rem;
                                width: 100%;
                                padding: .75rem;
                                background: $secondary;
                                justify-content: center;
                                border-radius: 2px;
                                max-height: 48px;

                                a {
                                    font-family: Roboto-Regular;
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 1.5rem;
                                    color: $white;
                                    text-transform: uppercase;

                                    @media (max-width: 960px) {
                                        font-size: 12px;
                                    }

                                    @media (max-width: 868px) {
                                        font-size: 12px;
                                        line-height: 1rem;
                                    }

                                    @media (max-width: 767px) {
                                        font-size: 16px;
                                        line-height: 1rem;
                                    }
                                }

                                img {
                                    width: auto;
                                    height: 1.4rem;
                                    margin-left: 1rem;
                                }
                            }
                        }
                    }
                }
            }
        }

        .textContainer {
            p {
                font-family: Roboto-Regular;
                font-size: 1rem;
                line-height: 1.5rem;
                color: $dark;
    
                a {
                    color: $primary;
                    text-decoration: underline;
                }
            }
        }
    }
}