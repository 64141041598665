@import "../../styles/variables.scss";
@import "../../styles/animations.scss";

.container {
    max-width: $max-width;
    margin:0 auto;
    padding: 76px 1rem;

    .backContainer {
        a {
            display: flex;

            p {
                font-family: Roboto-Regular;
                font-weight: 400;
                font-size: 1rem;
                color: $secondary;
                line-height: 1.5rem;
                margin-left: 0.25rem;
            }
        }
    }
    .mainToolContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 1rem 0;

        .toolDescriptionContainer {
            width: 100%;

            @media (min-width: $breakpoint-tablet) {
                width: 65%;
            }

            .titleContainer {
                width: 100%;
                display: flex;
                align-items: center;

                img {
                    max-width: 3rem;
                }

                h2 {
                    font-family: Muli-Regular;
                    font-weight: 400;
                    font-size: 1.5rem;
                    line-height: 2.18rem;
                    color: $dark;
                    margin-left: 0.5rem;
                    text-transform: capitalize;
                }
            }

            .descriptionContainer {
                margin-top: 2rem;
                font-family: Roboto-Regular;
                font-weight: 400;
                font-size: 1rem;
                color: $dark;
                line-height: 1.5rem;
                margin-bottom: 0.25rem;

                h2 {
                    font-family: Muli-SemiBold;
                    font-weight: 600;
                    font-size: 1rem;
                    color: $dark;
                    line-height: 1.5rem;
                }
            }

            .web {
                margin-top: 2rem;
                font-family: Roboto-Regular;
                font-weight: 400;
                font-size: 1rem;
                color: $dark;
                line-height: 1.5rem;
                margin-bottom: 0.25rem;

                h2 {
                    font-family: Muli-SemiBold;
                    font-weight: 600;
                    font-size: 1rem;
                    color: $dark;
                    line-height: 1.5rem;
                    margin-bottom: .5rem;
                }

                a {
                    font-size: 0.875rem;
                }
            }

            .keyFeaturesContainer {
                margin-top: 2rem;
                h2 {
                    font-family: Muli-SemiBold;
                    font-weight: 400;
                    font-size: 1rem;
                    color: $dark;
                    line-height: 1.5rem;
                }

                ul {
                    margin-top: 1rem;
                    li {
                        font-family: Roboto-Regular;
                        font-weight: 400;
                        font-size: 1rem;
                        color: $dark;
                        line-height: 1.25rem;
                        margin-top: 0.25rem;
                        list-style-type: none;
                    }
                }
            }
            
            .tableContainer {
                margin-top: 1.5rem;
                h2 {
                    font-family: Muli-SemiBold;
                    font-weight: 600;
                    font-size: 1rem;
                    color: $dark;
                    line-height: 1.5rem;

                    span {
                        font-family: Roboto-Regular;
                        font-weight: 400;
                        font-size: 1rem;
                        color: $dark;
                    }
                }
                .table {
                    margin-top: 0.625rem;
                    
                    @media (min-width: $breakpoint-tablet) {
                        margin-right: 1rem;
                    }

                    .row {
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        border: 1px solid $quaternary;

                        .header {
                            width: 30%;
                            padding: 0.6rem;
                            p {
                                font-family: Roboto-Regular;
                                font-weight: 400;
                                font-size: 0.875rem;
                                line-height: 1.25rem;
                                color: $tertiary;
                            }
                        }
    
                        .content {
                            width: 70%;
                            border-left: 1px solid $quaternary;
                            padding: 0.6rem;
                            p, span {
                                font-family: Roboto-Regular;
                                font-weight: 400;
                                font-size: 0.875rem;
                                line-height: 1.25rem;
                                color: $dark;
                            }
                        }
                    }

                }
            }

            .reportContainer {
                margin-top: 1.625rem;

                h2 {
                    font-family: Muli-SemiBold;
                    font-weight: 600;
                    font-size: 1rem;
                    color: $dark;
                    line-height: 1.5rem;
                }

                .reportRow {
                    margin-top: 1rem;
                    display: flex;
                    flex-wrap: wrap;

                    img {
                        align-items: flex-start;
                        height: 100%;
                        width: auto;
                        max-width: 50%;
                    }
                }

            }
        }
        .contactFormContainer {
            width: 100%;
            margin-top: 1.5rem;
            animation: fadeIn 0.5s cubic-bezier(0.165, 0.2, 0.44, 1) forwards;

            @media (min-width: $breakpoint-tablet) {
                width: 30%;
                margin-left: 1rem;
            }
        }
        .contactFormContainerOut {
          animation: fadeOut 0.4s 0.2s cubic-bezier(0.165, 0.2, 0.44, 1) forwards;
    
        }
    }

    .separator {
        display: flex;
        justify-content: center;
        hr {
            width: 20%;
            border-style: solid;
            border-image: linear-gradient(to right, $primary, $secondary) 100% 0;
        }
    }

}