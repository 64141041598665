@import "../../styles/variables.scss";

.container {
    padding: 100px 5%;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    min-height: 500px;

    @media only screen and (max-width: $breakpoint-tablet) {
        flex-direction: column-reverse;
    }

    .errorTextWrapper {
        flex: 1;
        
        div {
            width: fit-content;
            margin: auto;

            @media only screen and (max-width: $breakpoint-tablet) {
                text-align: center;
            }

            h1 {
                color: $primary-accent;
                font-family: Nunito-Sans-Bold;
                font-size: 12vw;
            }
    
            h3 {
                font-family: Nunito-Sans-Light;
                text-align: start;
                margin-top: 0;
                font-size: 2rem;
                font-weight: 500;

                @media only screen and (max-width: $breakpoint-logo-tablet) {
                    font-size: 1.3rem;
                }
            }

            .buttonContainer {
                width: 100%;
                margin-top: 2rem;
                min-height: 44px;
                width: 120px;
                background: $primary-accent;
                border-radius: 2px;
                text-align: center;
                position: relative;
                float: left;

                @media only screen and (max-width: $breakpoint-tablet) {
                    position: relative;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

                .button {
                    font-family: Roboto-Regular;
                    font-weight: 400;
                    font-size: 1rem;
                    text-align: center;
                    color: $white;
                    line-height: 44px;
                    cursor: pointer;
                    text-transform: uppercase;
                    background: none;
                    border: none;
                    border-radius: 2px;
                    width: 100%;
        
                    &:focus {
                        outline: none;
                    }
                }
            }
        }

    }

    .errorImage {
        flex: 1;
        text-align: center;

        .errorImg {
            max-width: 70%;
        }
    }

}