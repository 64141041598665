@import "../../styles/variables.scss";

.container {

    padding: 6rem 0;
    background: #292929;
    color: #fff;
    display: flex;
    justify-content: center;
    max-width: $max-width;
    margin: 0 auto;

    @media only screen and (max-width: $breakpoint-netbook) {
        padding: 1rem 0;
    }

    @media only screen and (max-width: $breakpoint-logo-tablet) {
        display: block;
    }

    .gridRow {
        width: 100%;
        max-width: $max-width;
        margin: 0 auto;
        padding: 55px 16px;
        display: flex;
        justify-content: space-between;
        flex-flow: row wrap;
        justify-self: center;

        &:first-child {
            @media only screen and (max-width: $breakpoint-netbook) {
                max-width: 40%;
            }
            @media only screen and (max-width: $breakpoint-logo-tablet) {
                max-width: 100%;
            }
        }

        @media only screen and (max-width: $breakpoint-logo-tablet) {
            flex: 0 1 100%;
            padding: 0px 16px;
            margin-bottom: 0px;
        }

        .gridCard {
            text-align: left;

            @media only screen and (max-width: 870px) {
                flex: 0 1 50%;
                margin-bottom: 40px;
            }

            @media only screen and (max-width: $breakpoint-logo-tablet) {
                flex: 0 1 100%;
                padding: 25px 0px;
                margin-bottom: 0px;
            }

            .address {
                box-sizing: border-box;
                text-decoration: none;
                font-size: 14.87px;
                font-weight: 400;
                font-family: Roboto-Regular;
                line-height: 22px;
                color: #fff;
                
                strong {
                    font-weight: 900;
                }

                img {
                    width: 108px;
                    border-width: 0px;
                    box-sizing: border-box;
                    height: auto;
                }

                .disclaimerLinks {
                    margin-top: 25px;
                    margin-left: 0;

                    ul {
                        list-style: none;
                        display: flex;
                        align-items: center;
        
                        li {
                            margin-left: 20px;
        
                            @media only screen and (max-width: $breakpoint-logo-tablet) {
                                margin-left: 0;
                                margin-top: 25px;
                                margin-right: 20px;
                            }

                            &:first-child {
                                margin-left: 0px;
                            }
        
                            a {
                                line-height: 24px;
                                color: #fff;
                                width: 25px;
                                height: 25px;
                                font-size: 25px;
                                box-sizing: border-box;
                                position: relative;
                                left: 0;
                                background-position: center top;
                                transition: .3s all ease;

                                &:hover {
                                    color: $primary;
                                }
                            }
                        }
                    }
                }
            }

            img {
                max-width: 400px;
                border-width: 0px;
                box-sizing: border-box;
                height: 150px;
                width: auto;
            }

            ul {
                list-style: none;

                li {
                    padding: 4px 0px;
                    a {
                        box-sizing: border-box;
                        text-decoration: none;
                        font-size: 16px;
                        font-weight: 400;
                        font-family: Roboto-Regular;
                        line-height: 25px;
                        transition: .2s all ease;
                        color: #fff;

                        &:hover {
                            color: $primary;
                        }
                    }
                }
            }

            .gridCardTitle {
                font-size: 1.1rem;
                font-family: Nunito-Sans-Bolder;
                font-weight: 900;
                letter-spacing: 2px;
                line-height: 27px;
                margin-bottom: 12px;

                img {
                    width: 176px;
                    border-width: 0px;
                    box-sizing: border-box;
                    height: auto;
                }
            }
        }
    }

    .row {
        display: flex;
        flex-wrap: wrap;
        max-width: $max-width;
        margin: 0 auto;
        padding: 0 1rem;
    }
}

.copyright {
    background: #292929;
    color: #fff;
    font-size: 20px;
    position: relative;
    padding: 24px 24px;
    box-sizing: border-box;

    .row {
        display: flex;
        justify-content: space-between;
        max-width: $max-width;
        margin: auto;

        @media only screen and (max-width: $breakpoint-logo-tablet) {
            display: block;
        }

        .copyrightText {
            width: 100%;
            margin: 0;
            font-family: Roboto-Regular;
            font-weight: 400;
            font-size: 14px;
            padding-bottom: 1rem;
            display: flex;
            justify-content: center;
            gap: 50px;

            a {
                box-sizing: border-box;
                text-decoration: none;
                font-weight: 400;
                transition: .2s all ease;
                color: #fff;

                &:hover {
                    color: $primary;
                }
            }
        }
    }
}
