$primary: #2ca895;
$primary-accent: #00758c;
$secondary: #0075b2;
$tertiary: #8f9194;
$quaternary: #f2f2f2;
$quinary: #666666;
$dark: #292929;
$denial: #9b1a06;
$process: #f5c434;
$btnSearch: #F2DA61;
$white: #ffffff;
$hr: #cccccc;

$gradient-desktop: linear-gradient(to right, #00c2b8, #00736e, #0075b2, #00abff) !important;
$gradient-tablet: linear-gradient(to right bottom, #00c2b8, #00736e, #0075b2, #00abff) !important;
$gradient-mobile: linear-gradient(180deg, #00c2b8, #00736e, #0075b2, #00abff) !important;

$breakpoint-logo-tablet: 550px;
$breakpoint-tablet: 768px;
$breakpoint-netbook: 991px;

$max-width: 1200px;
