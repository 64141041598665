@import "../../styles/variables.scss";

.container {
    background-image: $gradient-desktop;
    padding: 1rem 0;

    .row {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        max-width: $max-width;
        margin: 0 auto;
        padding: 0 1rem;

        .titleRow {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            width: 100%;
            max-width: $max-width;
            margin-bottom: 1rem;

            h2 {
                font-family: Nunito-Sans-Bold;
                font-weight: 400;
                font-size: 20px;
                line-height: 30px;
                color: $white;
            }
        }
    }
}

.containerHome {
    background-image: transparent;
    padding: 1rem 0;
    .row {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        max-width: $max-width;
        margin: 0 auto;
        padding: 0 1rem;

        .titleRow {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            width: 100%;
            max-width: $max-width;
            margin-bottom: 1rem;

            h2 {
                font-family: Nunito-Sans-Bold;
                font-weight: 400;
                font-size: 20px;
                line-height: 30px;
                color: $white;
            }
        }
    }
}