@import "../../styles/variables.scss";

.container {
    padding-top: 60px;

	.inputContainer {
		background: $gradient-desktop;
		padding: 1rem 0;

		.row {
			display: flex;
			flex-wrap: wrap;
			width: 100%;
			max-width: $max-width;
			margin: 0 auto;
			padding: 0 1rem;

			.titleRow {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				width: 100%;
				max-width: $max-width;
				margin-bottom: 1rem;

				h2 {
					font-family: Muli-Regular;
					font-weight: 400;
					font-size: 1.5rem;
					line-height: 2.18rem;
					color: $white;
				}
			}
		}
	}

    .fewCardsContainer {
        width: 100%;
        display: flex;

        @media only screen and (max-width: $breakpoint-logo-tablet) {
            display: block;
        }

        .cardContainer {
            width: 25%;

            @media only screen and (max-width: 1060px) {
                width: 33%;
            }

            @media only screen and (max-width: 810px) {
                width: 50%;
            }

            @media only screen and (max-width: $breakpoint-logo-tablet) {
                width: 100%;
            }

            @media only screen and (max-width: 400px) {
                width: 100%;
            }
        }
    }

    .cardsContainer {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;

        .cardContainer {
            width: 25%;

            @media only screen and (max-width: 1060px) {
                width: 33%;
            }

            @media only screen and (max-width: 810px) {
                width: 50%;
            }

            @media only screen and (max-width: $breakpoint-logo-tablet) {
                width: 100%;
            }

            @media only screen and (max-width: 400px) {
                
            }
        }
    }


    .textContainer {
        p {
            font-family: Roboto-Regular;
            font-size: 1rem;
            line-height: 1.5rem;
            color: $dark;

            a {
                color: $primary;
                text-decoration: underline;
            }
        }
    }

    .noResults {
        width: 100%;
        margin-top: 2rem;
        p {
            font-family: Roboto-Regular;
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.5rem;
            color: $dark;
            text-align: center;
        }

    }
}