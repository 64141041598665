@import "../../styles/variables.scss";      

.icons {
    width: 50%;
    height: auto;
    min-height: 100px;
    text-align: center;
    padding: 40px;
    max-width: 362px;
    flex: 33%;

    @media only screen and (max-width: $breakpoint-tablet) {
        flex: 0 1 50%;
    }

    @media only screen and (max-width: $breakpoint-logo-tablet) {
        flex: 0 1 100%;
        margin: auto;
    }

    &:nth-child(3) {
        width: 100%;

        @media (min-width: $breakpoint-tablet) {
            margin-top: 0;
            width: 33%;
        }
    }

    @media (min-width: $breakpoint-tablet) {
        width: 31%;
    }

    p {
        font-family: Roboto-Regular;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5rem;
        text-align: center;
        margin-top: 0.5rem;
        color: $dark;
    }
}

.iconsWidth {
    width: 100%;
    min-height: 100px;
    margin-top: 1rem;
    text-align: center;

    @media (min-width: $breakpoint-tablet) {
        margin-top: 0;
        width: 31%;
    }

    img {
        max-width: 54px;
        height: auto;
    }

    p {
        font-family: Roboto-Regular;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5rem;
        text-align: center;
        margin-top: 0.5rem;
        color: $dark;
    }
}