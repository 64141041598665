@import "../../styles/variables.scss";

.container {
  padding-top: 3.75rem;

  .slideContainer {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    min-height: 750px;
    align-items: center;

    @media only screen and (max-width: $breakpoint-netbook) {
      min-height: 850px;
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      min-height: 1200px;
    }

    .rowBgOverlay {
      background-image: $gradient-desktop;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: " ";
      z-index: 2;
      backface-visibility: hidden;

      @media only screen and (max-width: $breakpoint-tablet) {
        background-image: $gradient-tablet;
      }

      @media only screen and (max-width: $breakpoint-logo-tablet) {
        background-image: $gradient-mobile;
      }

      &::after,
      &::before {
        box-sizing: inherit;
      }
    }

    .homeFilterBar {
      display: block;
      position: absolute;
      width: 100%;
      top: 60%;
      padding: 60px 0px;
      z-index: 6;

      &:last-child {
        display: none;
      }

      @media (max-width: $breakpoint-netbook) {
        top: 65%;
      }

      @media only screen and (max-width: $breakpoint-tablet) {
        top: 57%;
        padding: 10px 0px;
      }

      @media (max-width: 570px) {
        top: 59%;
      }

      @media (max-width: $breakpoint-logo-tablet) {
        top: 55%;
      }
    }

    .separator {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;

      @media only screen and (max-width: $breakpoint-tablet) {
        margin-top: -2rem;
      }

      @media only screen and (max-width: $breakpoint-logo-tablet) {
        margin-top: 1rem;
      }

      hr {
        z-index: 5;
        width: 100%;
        border-style: solid $quaternary;
        opacity: 0.7;
      }
    }

    .advenceSvg {
      position: absolute;
      width: 100%;
      right: 0;
      top: auto;
      bottom: 0;
      height: 70px;
      z-index: 3;

      .nectarShape {
        width: 100%;
        left: 0;
        bottom: -1px;
        height: 100%;
        position: relative;
        fill: #fff;

        path:nth-child(1) {
          opacity: 0.15;
        }

        path:nth-child(2) {
          opacity: 0.3;
        }
      }
    }

    .slideRow {
      display: flex;
      position: relative;
      top: -100px;
      flex-direction: row-reverse;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      height: auto;
      max-width: $max-width;
      margin: auto auto;
      padding: 0 1rem;
      z-index: 4;

      @media only screen and (max-width: $breakpoint-netbook) {
        flex-direction: column;
      }

      @media only screen and (max-width: $breakpoint-tablet) {
        top: -170px;
      }

      @media only screen and (max-width: $breakpoint-logo-tablet) {
      }

      .slideText {
        flex: 1;
        width: 100%;
        position: relative;

        @media only screen and (max-width: $breakpoint-netbook) {
          width: 100%;
        }

        @media only screen and (max-width: $breakpoint-tablet) {
          margin-top: -130px;
        }

        @media only screen and (max-width: $breakpoint-logo-tablet) {
        }

        h1 {
          font-family: Nunito-Sans-Bold;
          font-weight: 400;
          font-size: 32px;
          line-height: 2.56rem;
          overflow-wrap: break-word;
          margin-bottom: 1.56rem;
          color: $white;
          max-width: 100%;

          @media only screen and (max-width: 1024px) {
            font-size: 28px;
          }

          @media only screen and (max-width: $breakpoint-netbook) {
            font-size: 24px;
            margin-bottom: -1rem;
            margin-top: 50px;
          }

          @media only screen and (max-width: $breakpoint-tablet) {
            margin-top: 50px;
          }

          @media only screen and (max-width: $breakpoint-logo-tablet) {
            margin-top: 0px;
          }

          @media (max-width: 360px) {
            font-size: 20px;
          }
        }

        h4 {
          font-family: Roboto-Regular;
          font-weight: 400;
          font-size: 18px;
          word-break: word-break;
          line-height: 1.56rem;
          margin-top: 1.56rem;
          color: $white;
          width: 100%;
          box-sizing: content-box;

          @media only screen and (max-width: $breakpoint-netbook) {
            margin-bottom: 10%;
          }

          @media only screen and (max-width: $breakpoint-tablet) {
            margin-bottom: -10%;
          }

          @media (max-width: 360px) {
            font-size: 16px;
          }
        }

        ul {
          li {
            position: relative;
            display: inline;
            font-family: Roboto-Regular;
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.62rem;
            color: $dark;
            padding-left: 0.5rem;

            @media (min-width: $breakpoint-tablet) {
              padding-left: 1rem;
            }

            span {
              position: absolute;
              height: 0.37rem;
              width: 0.37rem;
              background: $gradient-desktop;
              border-radius: 50%;
              top: 50%;
              transform: translateY(-50%);
            }

            &:nth-child(1) {
              padding-left: 0;
            }
          }
        }
      }

      .slideImage {
        position: relative;
        right: 0;
        display: flex;
        justify-content: center;
        align-content: flex-end;
        flex: 1;
        width: 100%;
        height: auto;

        @media only screen and (max-width: $breakpoint-netbook) {
          text-align: center;
          margin-top: 10%;
        }

        @media only screen and (max-width: $breakpoint-tablet) {
          text-align: center;
          margin-top: -40%;
        }

        @media only screen and (max-width: $breakpoint-logo-tablet) {
          margin-top: -200px;
          margin-bottom: 50px;
        }

        .videoDiv {
          display: flex;
          border: 10px solid transparent;
          border-image: url(../../assets/images/border_video.png) 20 round;
          border-image-outset: 0;
          padding: 0;
          margin: 0;
          height: 100%;
          width: 100%;
          max-height: 100%;
          max-width: 587px;
          margin-left: 50px;

          @media only screen and (max-width: $breakpoint-netbook) {
            max-width: 487px;
            margin-left: 0;
          }

          @media only screen and (max-width: $breakpoint-tablet) {
            margin-bottom: 130px;
          }

          @media only screen and (max-width: $breakpoint-logo-tablet) {
            max-width: 100%;
          }
        }

        video {
          height: 100%;
          margin: auto;
          position: relative;
          left: 50%;
          transform: translateX(-50%);
          max-height: 100%;
          aspect-ratio: 16/9;
        }

        .playButton {
          display: block;
          max-width: 30%;
          left: 0;
          margin: 0 auto;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translate(25px, -50%);
          cursor: pointer;
          z-index: 8;

          @media only screen and (max-width: $breakpoint-netbook) {
            max-width: 20%;
            transform: translate(0px, -50%);
          }

          @media only screen and (max-width: $breakpoint-tablet) {
            max-width: 20%;
            top: 40%;
            transform: translate(0px, -70%);
          }

          @media only screen and (max-width: $breakpoint-logo-tablet) {
            max-width: 25%;
            top: 40%;
            transform: translateY(-70%);
          }

          @media only screen and (max-width: 400px) {
            max-width: 25%;
            top: 35%;
            transform: translateY(-70%);
          }
        }

        .hidden {
          visibility: hidden;
        }
      }
    }
  }

  .chevronsMobile {
    display: flex;
    width: 100%;
    justify-content: center;

    img {
      margin: 0 10px;
    }
  }

  .sectionContainer {
    .expects {
      .iconsPresentation {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 2rem;
        width: 100%;
      }
    }
  }
  .newsletterBarContainer {
    position: relative;
    display: flex;
    background-image: $gradient-desktop;
    padding: 1rem 0;

    .newsletterRow {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      max-width: $max-width;
      margin: 0 auto;
      padding: 0 1rem;

      .descriptionContainer {
        position: relative;
        width: 100%;

        @media (min-width: $breakpoint-tablet) {
          max-width: 40%;
        }

        h2 {
          font-family: Nunito-Sans-Bold;
          font-weight: 400;
          font-size: 28px;
          line-height: 2.18rem;
          color: $white;

          &.alreadysuscribe {
            text-align: center;
          }
        }

        p {
          margin-top: 0.93rem;
          font-family: Roboto-Regular;
          font-weight: 400;
          font-size: 1rem;
          line-height: 1.25rem;
          color: $white;
        }
      }
      .iconContainer {
        position: absolute;
        top: 0;
        right: 0.5rem;

        @media (min-width: $breakpoint-tablet) {
          position: relative;
          width: 10%;
        }

        img {
          height: 90px;

          @media (min-width: $breakpoint-tablet) {
            height: 120px;
          }
        }
      }
      .formContainer {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 1rem;

        @media (min-width: $breakpoint-tablet) {
          margin-top: 0;
          max-width: 50%;
        }

        .inputContainer {
          width: 100%;

          @media (min-width: $breakpoint-tablet) {
            padding-left: 30px;
            width: 70%;
          }

          h5 {
            font-family: Nunito-Sans-Bold;
            font-weight: 600;
            font-size: 18px;
            line-height: 15px;
            color: $white;
            margin-bottom: 4px;
          }

          input {
            width: 100%;
            height: 100%;
            max-height: 44px;
            font-family: Nunito-Sans-SemiBold;
            color: $quinary !important;
            font-weight: 600;
            font-size: 1rem;
            line-height: 20px;
            color: $tertiary;
            border-color: transparent !important;
            border-radius: 2px;

            padding-left: 10px;

            &:focus {
              outline: none;

              &::-webkit-input-placeholder {
                color: transparent;
              }
            }
          }

          .error {
            font-family: Muli-Light;
            font-weight: 600;
            font-size: 0.75rem;
            line-height: 1.5rem;
            color: $denial;
          }
        }

        .buttonContainer {
          width: 100%;
          height: 100%;
          padding: 2.125rem 0 0 0;

          @media (min-width: $breakpoint-tablet) {
            padding: 1.125rem 0 0 1.875rem;
            width: 30%;
          }

          .button {
            width: 100%;
            max-height: 44px;
            border-radius: 2px;
            box-sizing: border-box;
            border: 2px solid $white;
            color: $white;
            transition: 0.2s all ease;
            background: transparent;
            display: flex;
            justify-content: center;
            cursor: pointer;

            &:hover {
              background: $white;
              color: $dark;
            }

            &:focus {
              outline: none;
            }

            p {
              font-family: Roboto-Regular;
              font-weight: 600;
              font-size: 1rem;
              line-height: 20px;
              text-transform: uppercase;
              line-height: 44px;
              letter-spacing: 1px;
            }
          }
        }
      }
    }
  }
}
