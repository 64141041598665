@import "../../styles/variables.scss";
@import "../../styles/animations.scss";

.asideContainer {
  flex-basis: 17%;
  max-height: 300px;
  box-sizing: border-box;
  padding: 1rem 5%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  animation: sliceUp 1s forwards;
  position: relative;
  top: 0;
  left: 0;
  background: rgba(255,255,255,0.9);
  box-shadow:0px 0px 20px rgba(0,0,0,0.15);

  .itemstyle {
    list-style: none;
    height: 50%;
    background: transparent;
    padding: 1rem 0;
      
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #00c2b8, #00736e, #0075b2, #00abff) 100% 0;
    border-top: 0;

    &:nth-child(5) {
      border: 0;
    }

    a {
      color: $dark;
      &:active, &:hover, a:visited {
          color: $primary
      }

      p {
          font-family: Roboto-Regular;
          font-weight: 400;
          font-size: 1rem;
          line-height: 1rem;
      }
    }
  }
}