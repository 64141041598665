@import "../../styles/variables.scss";

.selectContainer {
    width: 100%;
    margin-top: 0;
    padding-left: 0px;
    margin-top: 1rem;

    @media (min-width: $breakpoint-tablet) {
        padding-right: 30px;
        margin-top: 0;
    }

    &:nth-child(1) {
        padding-left: 0px;
    }

    @media (min-width: $breakpoint-tablet) {
        width: 25%;
    }

    h2 {
        font-family: Nunito-Sans-Bold;
        font-weight: 600;
        font-size: 16px;
        line-height: 15px;
        color: $white;
        margin-bottom: 4px;
    }

    input {
        width: 100%;
        height: 100%;
        max-height: 44px;
        font-family: Nunito-Sans-SemiBold;
        font-weight: 400;
        font-size: 1rem;
        line-height: 20px;
        color: $quinary;

        padding-left: 10px;

        &:focus {
            outline: none;
        }
    }
}