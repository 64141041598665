@import "../../styles/variables.scss";

.container {
    position: relative;
    box-shadow:0px 0px 20px rgba(0,0,0,0.15);
    border-radius: 2px;
    overflow: hidden;
    .handleBackground {
        position: absolute;
        width: 110%;
        height: 100%;
        opacity: 20%;
        background: $white;
        z-index: -2;
    }

    .formContainer {
        padding: 1rem 1.25rem;
        .title {
            h3 {
                font-family: Nunito-Sans-Bold;
                font-weight: 600;
                font-size: 24px;
                color: $dark;
                line-height: 1.5rem;
                text-transform: uppercase;
            }
            .separator {
                display: flex;
                justify-content: flex-start;
                margin: 0.5rem 0;
                hr {
                    width: 100%;
                    border-style: solid;
                    border-image: $gradient-desktop;
                }
            }
        }

        .form {
            .inputContainer {
                width: 100%;
                margin-top: 1rem;

                .customRow {
                    @media only screen and (max-width: $breakpoint-logo-tablet) {
                        flex-direction: column;

                    }
                    .error {
                        font-family: Roboto-Regular;
                        font-weight: 300;
                        font-size: 0.75rem;
                        line-height: 1.5rem;
                        color: $denial;
                    }
                }
            
                h6 {
                    font-family: Nunito-Sans-Bold;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 15px;
                    color: $dark;
                    margin-bottom: 4px;
                }

                select {
                    width: 100%;
                    height: 44px;
                    font-family: Roboto-Regular;
                    font-weight: 400;
                    font-size: 1rem;
                    line-height: 20px;
                    color: $tertiary;
                    background: #fff;
                    padding-left: 10px;
                    border: 1px solid $hr;
                    border-radius: 2px;

                    @media only screen and (max-width: $breakpoint-logo-tablet) {
                        flex: 100%;
                    }
                }

                input, textarea {
                    width: 100%;
                    height: 44px;
                    font-family: Roboto-Regular;
                    font-weight: 400;
                    font-size: 1rem;
                    line-height: 20px;
                    color: $tertiary;
            
                    padding-left: 10px;
                    border: 1px solid $hr;
                    border-radius: 2px;
            
                    &:focus {
                        outline-color: $secondary;

                        &::-webkit-input-placeholder 
                        {
                            color: transparent;
                        }
                    }
                }

                textarea {
                    padding-top: 0.5rem;
                    height: 88px;
                }

                .error {
                    font-family: Roboto-Regular;
                    font-weight: 300;
                    font-size: 0.75rem;
                    line-height: 1.5rem;
                    color: $denial;
                }
            }
        }

        .agreements {
            margin-top: 1rem;
            div {
                display: flex;
                p {
                    font-family: Roboto-Regular;
                    font-size: .75rem;
                    color: $tertiary;
                    padding-left: 10px;

                    span {
                        display: block;
                        margin-top: 10px;
                    }
                }
            }

            div:nth-child(2) {
                margin-top: 1rem;
            }

            .error {
                font-family: Roboto-Regular;
                font-weight: 300;
                font-size: 0.75rem;
                line-height: 1.5rem;
                color: $denial;
            }
        }

        .buttonContainer {
            margin-top: 1rem;
            min-height: 44px;
            background: $primary-accent;
            border-radius: 2px;
            .button {
                font-family: Roboto-Regular;
                font-weight: 400;
                font-size: 1rem;
                text-align: center;
                color: $white;
                line-height: 44px;
                cursor: pointer;
                text-transform: uppercase;
                background: none;
                border: none;
                border-radius: 2px;
                width: 100%;
    
                &:focus {
                    outline: none;
                }
            }
        }
    }

    
}
