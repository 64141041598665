@import "./styles/variables.scss";

// Muli

@font-face {
  font-family: "Muli-Light";
  src: url("./assets/fonts/Muli/Muli-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Muli-Regular";
  src: url("./assets/fonts/Muli/Muli-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Muli-Medium";
  src: url("./assets/fonts/Muli/Muli-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Muli-Bold";
  src: url("./assets/fonts/Muli/Muli-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Muli-SemiBold";
  src: url("./assets/fonts/Muli/Muli-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Muli-ExtraBold";
  src: url("./assets/fonts/Muli/Muli-ExtraBold.ttf") format("truetype");
}

// Roboto

@font-face {
  font-family: "Roboto";
  font-style: normal;
  src: url("./assets/fonts/Roboto/Roboto-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto-Regular";
  font-style: normal;
  src: url("./assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
}

// Nunito

@font-face {
  font-family: Nunito-Sans;
  font-style: normal;
  src: url("./assets/fonts/Nunito/NunitoSans-Regular.ttf");
}

@font-face {
  font-family: Nunito-Sans-Bolder;
  font-style: normal;
  src: url("./assets/fonts/Nunito/NunitoSans-ExtraBold.ttf");
}

@font-face {
  font-family: Nunito-Sans-Bold;
  font-style: normal;
  src: url("./assets/fonts/Nunito/NunitoSans-Bold.ttf");
}

@font-face {
  font-family: Nunito-Sans-SemiBold;
  font-style: normal;
  src: url("./assets/fonts/Nunito/NunitoSans-SemiBold.ttf");
}

@font-face {
  font-family: Nunito-Sans-Italic;
  font-style: normal;
  src: url("./assets/fonts/Nunito/NunitoSans-Italic.ttf");
}

@font-face {
  font-family: Nunito-Sans-Light;
  font-style: normal;
  src: url("./assets/fonts/Nunito/NunitoSans-Light.ttf");
}


/****
  END FONTS
****/


html {
  overflow-x: hidden;
  box-sizing: border-box;
}

body {
  margin: 0 auto;
  overflow: hidden;
  box-sizing: border-box;
  background-color: $white;
}

@media screen and (max-width: 720px) {
  body {
    min-width: unset;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

::-webkit-scrollbar {
  display: none;
}

#root {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  min-height: 100vh;
}

* {
  padding: 0;
  margin: 0;
}



