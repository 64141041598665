@import "../../styles/variables.scss";
@import "../../styles/animations.scss";

.container {
    padding-top: 60px;

    .filterBarContainer {
        background: linear-gradient(to right, $primary, $secondary) 100% 0;
        padding: 1rem 5%;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    .contactInformation {
        width: 100%;
        margin-top: 1.5rem;
        padding-right: 5%;

        @media (min-width: $breakpoint-tablet) {
            width: 60%;
        }

        .title {
            margin-bottom: 1rem;
            p {
                font-family: Roboto-Regular;
                font-weight: 400;
                font-size: 1rem;
                color: $dark;
                line-height: 1.5rem;
            }
        }
    }

    .officeInformation {
        h3 {
            font-family: Nunito-Sans-Bold;
            font-weight: 400;
            font-size: 24px;
            color: $dark;
            line-height: 1.5rem;
        }

        .separator {
            display: flex;
            justify-content: flex-start;
            margin: 0.5rem 0;
            hr {
                width: 25%;
                border-style: solid;
                border-image: linear-gradient(to right, $primary, $secondary) 100% 0;
            }
        }

        p {
            font-family: Roboto-Regular;
            font-weight: 400;
            font-size: 1rem;
            color: $dark;
            line-height: 1.5rem;
            margin-bottom: 2rem;
        }
    }

    .additionalInformation {
        p {
            font-family: Roboto-Regular;
            font-weight: 400;
            font-size: 1rem;
            color: $dark;
            line-height: 1.5rem;
            margin-bottom: 2rem;
            a {
                color: $secondary;
            }
        }
    }

    .contactFormContainer {
        width: 100%;
        margin-top: 2rem;
        animation: fadeIn 0.5s cubic-bezier(0.165, 0.2, 0.44, 1) forwards;

        @media (min-width: $breakpoint-tablet) {
            width: 40%;
            margin-top: 1.5rem;
        }
    }
    .contactFormContainerOut {
      animation: fadeOut 0.4s 0.2s cubic-bezier(0.165, 0.2, 0.44, 1) forwards;

    }
}