@import "../../styles/variables.scss";

.container {
    position: fixed;
    width: 100%;
    border-top:0;
    z-index: 8;
    margin-top: 60px;

    .row {
        padding: 0;
    }
}