@import "../../styles/variables.scss";

.container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 1rem 0;

    .listBlock {
        .cardSelected {
            margin: 0.375rem .25rem;
            list-style: none;
            display: inline-block;
            border: 1px solid $secondary;
            padding: 0 0.5rem;
            border-radius: 1rem;
            cursor: pointer;
            background: $secondary;
            
            p {
                font-family: Roboto-Regular;
                font-weight: 400;
                font-size: 1rem;
                line-height: 1.5rem;
                text-align: center;
                color: $white;
            }
        }

        .cardContainer {
            margin: 0.375rem .25rem;
            list-style: none;
            display: inline-block;
            border: 1px solid $secondary;
            padding: 0 0.5rem;
            border-radius: 1rem;
            cursor: pointer;
            
            p {
                font-family: Roboto-Regular;
                font-weight: 400;
                font-size: 1rem;
                line-height: 1.5rem;
                text-align: center;
                color: $secondary;
            }
        }
    }
}