@import "../../styles/animations.scss";
@import "../../styles/variables.scss";

.modalContainer {
  top: 0;
  left: 0;
  z-index: 30;
  display: block;
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.7);
  animation: fadeIn 0.5s cubic-bezier(0.165, 0.2, 0.44, 1) forwards;
}
.containerOut {
  animation: fadeOut 0.4s 0.2s cubic-bezier(0.165, 0.2, 0.44, 1) forwards;
}
.modalWrapper {
  display: inline-block;
  position: fixed;
  z-index: 31;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.modalContent {
  background-color: $white;
  border-radius: 7px;
  padding: 1em;
  display: inline-block;
  z-index: 40;
  min-width: 35vw;
  transform: scale(0);
  animation: scaleIn 0.3s 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  max-height: 90vh;
  overflow: scroll;
}
.out {
  animation: scaleOut 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}
.modalClose {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.5rem;
  background-color: transparent;
}