@import "../../styles/variables.scss";

.container {
    width: 100%;
    height: 100%;
    padding: 2.125rem 0 0 0;

    @media (min-width: $breakpoint-tablet) {
        width: 25%;
        padding: 1.125rem 0 0 0;
    }

    .button {
        width: 100%;
        height: 100%;
		border: none;
        border-radius: 2px;
        background: $btnSearch;
        display: flex;
        justify-content: center;
        cursor: pointer;

        p {
            font-family: Muli-SemiBold;
            font-weight: 600;
            font-size: 1rem;
            line-height: 20px;
            color: $dark;
            text-transform: uppercase;
            line-height: 44px;
        }
    }

	.disabledbutton {
		opacity: 0.7;
        cursor: not-allowed;
	}
}