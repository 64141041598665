*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: #292929
}

.active-link {
  color: #00758c !important
}

.css-4ljt47-MenuList div:last-child {
  border-bottom: none !important;
}