@import "../../styles/variables.scss";

.cardContainer {
    padding: 0 .25rem;
    margin: 1.5rem 0.93rem 2.5rem 0.93rem;

    .shadowFaker {
        display: flex;
        flex-direction: column;
        // padding: 1rem 0.5rem; 
        box-shadow:0px 0px 20px rgba(0,0,0,0.15);
        // height: 333px;
        min-height: 15.93rem;
        border-radius: 2px;

        .logoContainer {
            display: flex;
            justify-content: center;
            width: 100%;
            min-height: 85px;
            position: relative;
            // background-position: center, center;
            // background-repeat: no-repeat;

            .logo {
                min-height: 50px;
                position: absolute;
                top: 100%;
                transform: translateY(-50%);
                img {
                    width: auto;
                    max-width: 100%;
                    max-height: 2.87rem;
                }
            }
        }
        .titleContainer {
            margin-top: 2rem;
            min-height: 5rem;
            max-height: 80px;
            justify-content: center;
            align-items: center;
            h4 {
                font-family: Nunito-Sans;
                font-weight: 400;
                font-size: 18px;
                color: $dark;
                line-height: 1.5rem;
                text-align: center;
            }
            
        }

        .tagContainer {
            display: flex;
            flex-wrap: wrap;
            font-family: Roboto-Regular;
            min-height: 1.125rem;
            justify-content: center;
            padding: 0.5rem 0;
            .tagManager {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                position: relative;
                
                .tooltipContainer {
                    float: left;
                    margin-top: 4.5rem;
                    padding: 0.5rem;
                    padding-bottom: 1rem;
                    position: absolute;
                    width: max-content;
                    display: block;
                    z-index: 1000;
                    min-width: 7rem;
                    background: rgba(41, 41, 41, 0.8);
                    color:$white;
                    text-align: center;
                    left: 50%;
                    transform: translateX(-50%);
                    border-radius: 2px;               

                    &:before{
                        content:'';
                        display:block;
                        width:0;
                        height:0;
                        position:absolute;
                    
                        border-right: 8px solid transparent;
                        border-left: 8px solid transparent;
                        border-bottom:8px solid rgba(41, 41, 41, 0.8);
                        left:50%;
                        top:-0.5rem
                    }

                    .toolTipTextContainer {
                        max-width: 300px;
                        span {
                            line-height: 1.1rem;
                            font-size: 0.8rem;
                        }
                    }
                }

                .cardManagerTags {
                    margin: 0.2rem;
                    font-family: Roboto-Regular;
                    font-weight: 400;
                    font-size: 0.75rem;
                    text-align: center;
                    color: $dark;
                    line-height: 1.125rem;
                    background: $quaternary;
                    padding: 0.1rem 0.4rem;
                    border-radius: 20px;
                    cursor: default;
                    max-height: 23px;
    
                    &:nth-child(1) {
                        margin-left: 0;
                    }
                    &:nth-child(2) {
                        margin-left: 0;
                    }
                }
                .click {
                    cursor: default;
                    padding: 0.1rem 0.8rem
                }
            }
        }

        .subTitleContainer {
            padding: 0.5rem 0;
            min-height: 1.85rem;
            p {
                font-family: Roboto-Regular;
                font-weight: 400;
                font-size: 12px;
                text-align: center;
                color: $dark;
                line-height: 1rem;
            }
        }

        .buttonContainer {
            margin: 1rem;
            background: $primary-accent;
            padding: 0.62rem 0;
            border-radius: 2px;
            position: relative;
            .button {

                a {
                    p {
                        font-family: Roboto-Regular;
                        font-weight: 600;
                        font-size: 1rem;
                        text-align: center;
                        color: $white;
                        line-height: 1.5rem;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
}