@import "../../styles/variables.scss";

.container {
    position: fixed;
    background: rgba(255,255,255,0.9);
    width: 100%;
    height: 60px;
    border-width: 1px;
    border-style: solid;
    border-image: $gradient-desktop;
    border-top:0;
    z-index: 10;

    .row {
        max-width: $max-width;
        margin:0 auto;
        padding: 0 1rem;
    }
}