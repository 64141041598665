@import "../../styles/variables.scss";

.container {
    display: flex;
    justify-content: space-between;
    height: 60px;
    align-items: center;

    .logoContainer {
        width: 80%;

        img {
            height: 60px;
            padding-top: 0.5rem;
            width: 100%;
        }

        @media (min-width: $breakpoint-logo-tablet) {
            width: 60%;
        }

        @media (min-width: $breakpoint-tablet) {
            width: 60%;
        }
    }

    .optionsContainer {
        width: 50%;

        @media (min-width: $breakpoint-tablet) {
            width: 70%;
        }

        .listContainer {
            text-align: end;

            .itemstyle {
                list-style: none;
                display: inline-block;
                margin: 0 0.81rem;
                
                @media (min-width: $breakpoint-tablet) {
                    margin: 0 0.21rem;
                }

                @media (min-width: $breakpoint-netbook) {
                    margin: 0 0.81rem;
                }

                &:nth-child(1) {
                    margin-left: 0;
                }
    
                &:nth-child(5) {
                    margin-right: 0;
                }

                a {
                    &:active, &:hover, a:visited {
                        color: $primary-accent;
                    }

                    p {
                        font-family: Roboto-Regular;
                        font-weight: 400;
                        font-size: 1em;
                        line-height: 20px;
                        text-align: center;

                        @media (min-width: $breakpoint-tablet) {
                            font-size: 0.8em;
                        }

                        @media (min-width: $breakpoint-netbook) {
                            font-size: 1em;
                        }
                    }
                }
            }

            .buttonQmenta {
                padding: 0.5rem;
                border: 1px solid $primary-accent;
                border-radius: 2px;
                background: #fff;

                p {
                    display: flex;
                    align-items: center;
                    font-weight: bolder!important;
                    
                    img {
                        margin-left: 0.6rem;
                        width: 1rem;
                        height: auto;
                    }

                }

                
            }
        }
    }
    .hamburger {
        span {
            background: $gradient-desktop;
        }
    }
}