@import "../../styles/variables.scss";

.container {
    max-width: $max-width;
    margin: 0 auto;
    padding: 0 1rem;

    @media screen and (max-width: 1230px) {
        max-width: calc($max-width - 100px);
        padding: 0 1.2rem;
    }

    @media screen and (max-width: 1130px) {
        max-width: calc($max-width - 140px);
        padding: 0 1.3rem;
    }

    @media screen and (max-width: 1095px) {
        max-width: calc($max-width - 180px);
        padding: 0 1.4rem;
    }

    @media screen and (max-width: 1043px) {
        max-width: calc($max-width - 220px);
        padding: 0 1.5rem;
    }

    .text {
        h2 {
            font-family: Nunito-Sans-Bold;
            font-weight: 600;
            font-size: 1.5rem;
            line-height: 35px;
            text-align: center;
            margin-top: 3rem;
            color: $dark;
        }
    
        p {
            text-align: center;
            margin-top: 0.5rem;
            font-family: Roboto-Regular;
            font-weight: 400;
            font-size: 18px;
            line-height: 1.5rem;
            text-align: center;
            color: $dark;
        }
    }


    .cardInterestContainer {
        padding: 0;
        margin-bottom: 1rem;

        @media (min-width: $breakpoint-tablet) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

    }

    .searchContainer {
        padding: 0;
        margin-bottom: 40px;

        @media (min-width: $breakpoint-tablet) {
            display: flex;
            flex-wrap: wrap;
        }

    }

    .separator {
        display: flex;
        justify-content: center;
        hr {
            width: 20%;
            border-style: solid;
            border-image: linear-gradient(to right, #00c2b8, #00736e, #0075b2, #00abff) 100% 0;
        }
    } 
}