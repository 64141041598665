@import "../../styles/animations.scss";
@import "../../styles/variables.scss";

.finishContent {
    min-height: 30vh;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    

    h3 {
      font-family: Muli-SemiBold;
      font-weight: 600;
      font-size: 2rem;
      color: $dark;
      line-height: 3rem;
      text-align: center;
    }

    p {
      text-align: center;
      margin-top: 0.5rem;
      font-family: Roboto-Regular;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5rem;
      text-align: center;
      color: $dark;
    }
}
